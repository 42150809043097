export const designColors = {
    black: '#000',
    blue: '#0076a5',

    grey: '#D8D8D8',

    green: '#4a773c',

    red: '#da291c',

    pink: '#c63663',

    purple: '#6f5091',

    yellow: '#fdda24',

    hover: '#11a2de',
    darkGrey: '#424242',
    darkGrey2: '#222'
};

export const getAccordionColor = (_theme, status) => {
    //Todo:Will replace colors with theme color and dark mode once design is fixed
    let color = '';
    status = status + '';
    switch (parseInt(status)) {
        case 0:
            color = designColors.blue;
            break;
        case 1:
            color = designColors.yellow;
            break;
        case 2:
            color = designColors.green;
            break;
        case 3:
            color = designColors.red;
            break;
        default:
            color = designColors.red;
    }
    return color;
};

export const statusTypes = [
    {
        text: 'Submitted',
        val: '0',
        description: 'Status is Submitted'
    },
    {
        text: 'In Process',
        val: '1',
        description: 'Status is In Process'
    },
    {
        text: 'Success',
        val: '2',
        description: 'Status is Success'
    },
    {
        text: 'Failed',
        val: '3',
        description: 'Status is Failed'
    }
];
export const statusCodeToDisplayActionPortal = 3;

export const middlewareNoLoaderConfig = {};

export const middlewareNoMappingConfig = {
    overrideRequestMapping: true,
    overrideResponseMapping: true
};

export const middlewareConfig = (noLoader, noMapping, loadingMessage) => {
    let config = {};
    if (noLoader) {
        config = { ...config, ...middlewareNoLoaderConfig };
    }

    if (noMapping) {
        config = { ...config, ...middlewareNoMappingConfig };
    }

    if (loadingMessage) {
        config = { ...config, loadingMessage };
    }

    return config;
};

export const entityMetaData = {
    samples: {
        EntityName: 'samples',
        EntityDescription: 'samples',
        Fields: [
            {
                FieldName: 'SampleId',
                DisplayName: 'samples id',
                Datatype: 'Text',
                ColumnName: 'samples id',
                IsSearchable: true,
                IsUpdatable: true,
                IsUnique: true,
                Order: 1,
                ConditionalField: false,
                ConditionalRule: null,
                IsRequired: true
            },
            {
                FieldName: 'BerryType',
                DisplayName: 'Berry Type',
                Datatype: 'Attribute',
                ColumnName: 'BerryType',
                Options: [
                    {
                        Order: '1',
                        Id: '1',
                        Descript: 'BLACK'
                    },
                    {
                        Order: '2',
                        Id: '2',
                        Descript: 'BLUE'
                    },
                    {
                        Order: '3',
                        Id: '3',
                        Descript: 'RASP'
                    },
                    {
                        Order: '4',
                        Id: '4',
                        Descript: 'STRAW'
                    }
                ],
                IsSearchable: true,
                IsUpdatable: false,
                IsUnique: false,
                Order: 2,
                ConditionalField: false,
                ConditionalRule: null,
                IsRequired: false,
                OptionLabel: 'Descript',
                OptionValue: 'Id',
                hasOptions: true
            },
            {
                FieldName: 'Proprietary',
                DisplayName: 'Proprietary',
                Datatype: 'Bool',
                ColumnName: 'Proprietary',
                IsSearchable: false,
                IsUpdatable: true,
                IsUnique: false,
                Order: 3,
                ConditionalField: false,
                ConditionalRule: [
                    {
                        Condition: 'Include if',
                        Value: 'Rasp'
                    },
                    {
                        Condition: 'Include if',
                        Value: 'Black'
                    }
                ],
                IsRequired: true
            }
        ],
        DisplayName: 'samples'
    },
    varmarkers: {
        EntityName: 'varmarkers',
        EntityDescription: 'varmarkers',
        Fields: [
            {
                FieldName: 'AlternateAllele',
                DisplayName: 'AlternateAllele',
                Datatype: 'Text',
                ColumnName: 'AlternateAllele',
                IsSearchable: true,
                IsUpdatable: true,
                IsUnique: true,
                Order: 1,
                ConditionalField: false,
                ConditionalRule: null,
                IsRequired: true
            },
            {
                FieldName: 'ReferenceAllele',
                DisplayName: 'ReferenceAllele',
                Datatype: 'Text',
                ColumnName: 'ReferenceAllele',
                IsSearchable: true,
                IsUpdatable: true,
                IsUnique: true,
                Order: 1,
                ConditionalField: false,
                ConditionalRule: null,
                IsRequired: true
            },
            {
                FieldName: 'VarmarkerId',
                DisplayName: 'VarmarkerId',
                Datatype: 'Text',
                ColumnName: 'VarmarkerId',
                IsSearchable: true,
                IsUpdatable: true,
                IsUnique: true,
                Order: 1,
                ConditionalField: false,
                ConditionalRule: null,
                IsRequired: true
            }
        ]
    },
    varmarkersets: {
        EntityName: 'varmarkersets',
        EntityDescription: 'varmarker sets',
        Fields: [
            {
                FieldName: 'VarmarkerSetId',
                DisplayName: 'Varmarker SetId',
                Datatype: 'Text',
                ColumnName: 'Varmarker SetId',
                IsSearchable: true,
                IsUpdatable: true,
                IsUnique: true,
                Order: 1,
                ConditionalField: false,
                ConditionalRule: null,
                IsRequired: true
            }
        ],
        DisplayName: 'Varmarker Set'
    },
    vendors: {
        EntityName: 'vendors',
        EntityDescription: 'vendors',
        Fields: [
            {
                FieldName: 'VendorName',
                DisplayName: 'Vendor Name',
                Datatype: 'Text',
                ColumnName: 'Vendor Name',
                IsSearchable: true,
                IsUpdatable: true,
                IsUnique: true,
                Order: 1,
                ConditionalField: false,
                ConditionalRule: null,
                IsRequired: true
            }
        ],
        DisplayName: 'vendors'
    }
};

export const masterDataMandatoryEditFields = {
    samples: [],
    varmarkers: ['VarmarkersPKId'],
    varmarkersets: ['VarmarkerSetPKId'],
    vendors: ['VendorId']
};

export const masterDataColumns = {
    samples: [
        {
            key: 'Actions',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'Actions'
                },
                _owner: null,
                _store: {}
            },
            frozen: true
        },
        {
            key: 'SampleId',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'SampleId'
                },
                _owner: null,
                _store: {}
            },
            editable: true,
            editorType: 'decimal',
            required: true,
            editorOptions: [
                {
                    label: 'No Value',
                    value: 'No Value'
                }
            ],
            isUnique: true,
            editOrder: 1
        }
    ],
    varmarkers: [
        {
            key: 'Actions',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'Actions'
                },
                _owner: null,
                _store: {}
            },
            frozen: true
        },
        {
            key: 'AlternateAllele',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'AlternateAllele'
                },
                _owner: null,
                _store: {}
            },
            editable: true,
            editorType: 'decimal',
            required: true,
            editorOptions: [
                {
                    label: 'No Value',
                    value: 'No Value'
                }
            ],
            isUnique: true,
            editOrder: 1
        },
        {
            key: 'ReferenceAllele',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'ReferenceAllele'
                },
                _owner: null,
                _store: {}
            },
            editable: true,
            editorType: 'decimal',
            required: true,
            editorOptions: [
                {
                    label: 'No Value',
                    value: 'No Value'
                }
            ],
            isUnique: true,
            editOrder: 1
        },
        {
            key: 'VarmarkerId',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'VarmarkerId'
                },
                _owner: null,
                _store: {}
            },
            editable: true,
            editorType: 'decimal',
            required: true,
            editorOptions: [
                {
                    label: 'No Value',
                    value: 'No Value'
                }
            ],
            isUnique: true,
            editOrder: 1
        }
    ],
    varmarkersets: [
        {
            key: 'Actions',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'Actions'
                },
                _owner: null,
                _store: {}
            },
            frozen: true
        },
        {
            key: 'VarmarkerSetId',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'VarmarkerSetId'
                },
                _owner: null,
                _store: {}
            },
            editable: true,
            editorType: 'decimal',
            required: true,
            editorOptions: [
                {
                    label: 'No Value',
                    value: 'No Value'
                }
            ],
            isUnique: true,
            editOrder: 1
        }
    ],
    vendors: [
        {
            key: 'Actions',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'Actions'
                },
                _owner: null,
                _store: {}
            },
            frozen: true
        },
        {
            key: 'VendorName',
            name: {
                key: null,
                ref: null,
                props: {
                    children: 'VendorName'
                },
                _owner: null,
                _store: {}
            },
            editable: true,
            editorType: 'decimal',
            required: true,
            editorOptions: [
                {
                    label: 'No Value',
                    value: 'No Value'
                }
            ],
            isUnique: true,
            editOrder: 1
        }
    ]
};

export const columns = [
    {
        key: 'Actions',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Actions'
            },
            _owner: null,
            _store: {}
        },
        frozen: true
    },
    {
        key: 'sampleid',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Selection'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: true,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: true,
        editOrder: 1
    },
    {
        key: 'FloweringType',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'FloweringType'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 2
    },
    {
        key: 'Series',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Series'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 3
    },
    {
        key: 'Species',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Species'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 4
    },
    {
        key: 'ParentYear',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'ParentYear'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 5
    },
    {
        key: 'OwnershipType',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'OwnershipType'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 6
    },
    {
        key: 'Comments',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Comments'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 7
    },
    {
        key: 'ActiveStatus',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Active Status'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'select',
        required: false,
        editorOptions: [
            {
                label: 'Active',
                value: 'Active'
            },
            {
                label: 'Inactive',
                value: 'Inactive'
            },
            {
                label: 'Void',
                value: 'Void'
            }
        ],
        isUnique: false,
        editOrder: 8
    },
    {
        key: 'Advanced',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Advanced'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 9
    },
    {
        key: 'BerryType',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Berry Type'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'berrySelect',
        required: false,
        editorOptions: [
            {
                label: 'BLACK',
                value: '1'
            },
            {
                label: 'BLUE',
                value: '2'
            },
            {
                label: 'RASP',
                value: '3'
            },
            {
                label: 'STRAW',
                value: '4'
            }
        ],
        isUnique: false,
        editOrder: 10
    },
    {
        key: 'SeedParent',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'SeedParent'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 11
    },
    {
        key: 'PollenParent',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'PollenParent'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 12
    },
    {
        key: 'SeedlingRow',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'SeedlingRow'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 13
    },
    {
        key: 'SeedlingPlant',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'SeedlingPlant'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 14
    },
    {
        key: 'SeedingYear',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'SeedlingYear'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 15
    },
    {
        key: 'SXA_BerryColor',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'SXA_Berry Color'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'text',
        required: null,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 16
    },
    {
        key: 'Proprietary',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Proprietary'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: true,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 17
    },
    {
        key: 'VarietyName',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'VarietyName'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 18
    },
    {
        key: 'SrcBreedingProgram',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'SourceBreedingProgram'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'select',
        required: true,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 19
    },
    {
        key: 'SelectedDate',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'SelectedDate'
            },
            _owner: null,
            _store: {}
        },
        editable: true,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 20
    },
    {
        key: 'OverallStatus',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'OverallStatus'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'select',
        required: false,
        editorOptions: [
            {
                label: 'Public',
                value: 'Public'
            },
            {
                label: 'Germplasm',
                value: 'Germplasm'
            },
            {
                label: 'Parent',
                value: 'Parent'
            },
            {
                label: 'Variety',
                value: 'Variety'
            },
            {
                label: 'Discard',
                value: 'Discard'
            },
            {
                label: 'Active',
                value: 'Active'
            }
        ],
        isUnique: false,
        editOrder: 100
    },
    {
        key: 'OverallStatusModifiedBy',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'OverallStatusModifiedBy'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'decimal',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 100
    },
    {
        key: 'OverallStatusModifiedDate',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Overall Status Modified Date'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'date',
        required: null,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 100
    },
    {
        key: 'CreatedBy',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Created By'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'text',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 100
    },
    {
        key: 'CreatedDateTime',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Created Date Time'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'date',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 100
    },
    {
        key: 'ModifiedBy',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Modified By'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'text',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 100
    },
    {
        key: 'ModifiedDateTime',
        name: {
            key: null,
            ref: null,
            props: {
                children: 'Modified Date Time'
            },
            _owner: null,
            _store: {}
        },
        editable: false,
        editorType: 'date',
        required: false,
        editorOptions: [
            {
                label: 'No Value',
                value: 'No Value'
            }
        ],
        isUnique: false,
        editOrder: 100
    }
];

export const rowData = {
    SelectionId: 26504,
    Selection: '001AA  6',
    VarietyName: null,
    BerryType: 'STRAW',
    SrcBreedingProgram: 'WATS',
    Proprietary: true,
    OverallStatus: 'Parent',
    OverallStatusModifiedBy: null,
    OverallStatusModifiedDate: null,
    SelectedDate: null,
    FloweringType: 'ShortDay',
    Series: 'dummy',
    Species: null,
    ParentYear: '2013',
    OwnershipType: null,
    Comments: null,
    ActiveStatus: 'Active',
    Active: true,
    ActiveStartDate: 'Jan-01-1941',
    ActiveEndDate: 'Dec-31-2050',
    Advanced: false,
    SeedParent: '43R 78',
    PollenParent: 'Alexandria',
    SeedlingRow: null,
    SeedlingPlant: null,
    SeedingYear: null,
    SXA_BotanicalGroup: null,
    SXA_Spine: null,
    SXA_BerryColor: null,
    SXA_SpineColor: null,
    SXA_ChinaSelection: null,
    CreatedBy: 'STRAWFIX',
    CreatedDateTime: 'Feb-12-2016 12:36 AM',
    ModifiedBy: 'retheesh.rama@driscolls.com',
    ModifiedDateTime: 'Sep-09-2021 06:49 PM',
    Actions: 'Actions'
};

export const middlewareNoMappingAndLoaderConfig = {
    ...middlewareNoLoaderConfig,
    ...middlewareNoMappingConfig
};

export const productionSystemValues = [
    'Organic long cane',
    'Conventional long cane',
    'Conventional Soil',
    'Organic Soil',
    'Organic Substrate',
    'Conventional Substrate',
    'Tunneled conventional soil',
    'Open Air conventional soil',
    'Tunneled organic soil',
    'Open air organic soil',
    'Tunneled Substrate',
    'Open Air Substrate',
    'Tunneled organic substrate',
    'Open Air organic substrate'
];

export const boolColumns = ['uploadStatus'];

export const templateColumnsMap = {
    //samples
    SampleId: 'Sample Id',

    //varmarkers
    ReferenceAllele: 'Reference Allele',
    AlternateAllele: 'Alternate Allele',
    VarmarkerId: 'Varmarker Id',

    //VarmarkerSet
    VarmarkerSetId: 'Varmarker Set Id',

    //vendor
    VendorName: 'Vendor Name',

    UpdatedBy: 'Updated By',
    UpdatedDateTime: 'Updated Date Time',
    CreatedBy: 'Created By',
    CreatedDateTime: 'Created Date Time'
};
export const dateFields = [
    'lastUpdatedDateTime',
    'createdDateTime',
    'dateOfMostRecentDataUpdate',
    'UploadedDateTime',
    'harvestWeekBeg',
    'harvestWeekEnd',
    'CreatedDateTime',
    'UpdatedDateTime',
    'ModifiedDateTime'
];
export const dateTimeFields = ['lastUpdatedDateTime', 'createdDateTime', 'UploadedDateTime', 'CreatedDateTime', 'UpdatedDateTime'];

export const emailFields = ['UpdatedBy', 'CreatedBy'];

export const uploadValidationFields = {
    // FE code has been written to handle
    // types:string,number
    // condition: includes, <, >
    // Adding new types or condition needs code change where uploadValidationFields being used in component.

    geno_file_name: { status: true, type: 'string', condition: '' },
    vendor: { status: true, type: 'string', condition: '' },
    varmarker_set: { status: true, type: 'string', condition: '' },
    berry: { status: true, type: 'string', condition: 'include', conditionData: ['strawberry', 'blueberry', 'blackberry', 'raspberry'] },
    number_of_variants: { status: true, type: 'number', condition: '>', conditionData: 0 },
    number_of_samples: { status: true, type: 'number', condition: '>', conditionData: 0 }
};

export const berryTypes = {
    1: {
        label: 'Blackberry'
    },
    2: {
        label: 'Blueberry'
    },
    3: {
        label: 'Raspberry'
    },
    4: {
        label: 'Strawberry'
    },
    BLUE: {
        label: 'Blueberry'
    },
    BLACK: {
        label: 'Blackberry'
    },
    STRAW: {
        label: 'Strawberry'
    },
    RASP: {
        label: 'Raspberry'
    }
};

export const berryNameMapping = {};

// Count of header rows, including samples
export const headerRowCount = 7;

//Do not change the order of entityList
export const entityList = [
    {
        label: 'Samples',
        value: 'Samples'
    },
    {
        label: 'Varmarkers',
        value: 'Varmarkers'
    },
    {
        label: 'Varmarker Sets',
        value: 'VarmarkerSets'
    },
    {
        label: 'Vendors',
        value: 'Vendors'
    }
];

export const filtersForRDR = {
    uploadstatus: {
        label: 'Status',
        type: 'select',
        name: 'uploadstatus'
    },
    uploadedby: {
        label: 'Uploaded By',
        type: 'select',
        name: 'uploadedby'
    },
    berrytype: {
        label: 'Berry type',
        type: 'DrcBerrySelect',
        name: 'berrytype'
    },
    filename: {
        label: 'File Name',
        type: 'text',
        name: 'filename'
    },
    genofilename: {
        label: 'Geno File Name',
        type: 'text',
        name: 'genofilename'
    }
};

export const filtersForEntities = {
    Samples: {
        SampleId: {
            label: 'Sample Id',
            type: 'async',
            name: 'SampleId'
        },
        CreatedBy: {
            label: 'Created By',
            type: 'select',
            name: 'CreatedBy'
        }
    },
    Varmarkers: {
        ReferenceAllele: {
            label: 'Reference Allele',
            type: 'text',
            name: 'ReferenceAllele'
        },
        AlternateAllele: {
            label: 'Alternate Allele',
            type: 'text',
            name: 'AlternateAllele'
        },
        VarmarkerId: {
            label: 'Varmarker Id',
            type: 'async',
            name: 'VarmarkerId'
        },
        // UpdatedBy: {
        //     label: 'Updated By',
        //     type: 'select',
        //     name: 'UpdatedBy'
        // },
        CreatedBy: {
            label: 'Created By',
            type: 'select',
            name: 'CreatedBy'
        }
    },
    VarmarkerSets: {
        VarmarkerSetId: {
            label: 'VarmarkerSet Id',
            type: 'async',
            name: 'VarmarkerSetId'
        },
        UpdatedBy: {
            label: 'Updated By',
            type: 'select',
            name: 'UpdatedBy'
        },
        CreatedBy: {
            label: 'Created By',
            type: 'select',
            name: 'CreatedBy'
        }
    },
    Vendors: {
        VendorName: {
            label: 'Vendor Name',
            type: 'async',
            name: 'VendorName'
        },
        UpdatedBy: {
            label: 'Updated By',
            type: 'select',
            name: 'UpdatedBy'
        },
        CreatedBy: {
            label: 'Created By',
            type: 'select',
            name: 'CreatedBy'
        }
    }
};

export const exportFields = {
    BerryType: {
        label: 'Berry Type *',
        type: 'berrySelect',
        name: 'BerryType'
    },
    SampleId: {
        label: 'Sample Id',
        type: 'async',
        name: 'SampleId',
        isMulti: true
    },
    VarmarkerId: {
        label: 'Varmarker Id',
        type: 'async',
        name: 'VarmarkerId',
        isMulti: true
    },
    // genofilename: {
    //     label: 'Geno Filename',
    //     type: 'async',
    //     name: 'genofilename',
    //     isMulti: false
    // },
    
    // DateRange: {
    //     label: 'Created Date Range',
    //     type: 'date',
    //     name: 'DateRange'
    // }
};

export const dataBricksStatus = {
    0:'Job Initiated',
    1:'Success',
    2:'Failure'
};