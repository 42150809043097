import React from 'react';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { SERVICE_WORKER_UPDATE } from './actions/actions';
import { Store } from './data/store';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

import App from './App';

const renderApp = () => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
        <Provider store={Store}>
            <LocalizeProvider>
                <App />
            </LocalizeProvider>
        </Provider>,
    );
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

const serviceWorkerConfig = {
    onUpdate: (reg) => Store.dispatch({ type: SERVICE_WORKER_UPDATE, payload: reg })
};

serviceWorker.register(serviceWorkerConfig);
renderApp();
