import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { designColors } from '../../data/constant';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    listItemRoot: {
        color: '#000 !important',
        backgroundColor: `${designColors.grey} !important`,
        fontWeight: 'bold',
        minWidth: 32,
        paddingLeft: theme.spacing(4)
    },
    selected: {
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        fontWeight: 'bold',
        paddingLeft: theme.spacing(4)
    }
}));

function NestedMenuItem(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedSubitemIndex] = React.useState(-1);
    const { menu, selected, setSelectedIndex } = props;
    useEffect(() => {
        menu.items?.map((itm, indx) => {
            if (selected && itm.route.includes(props.history.location.pathname)) setSelectedSubitemIndex(indx);
        });
    }, []);
    return (
        <List component="div" disablePadding>
            {menu.items.map((itm, indx) => (
                <ListItem
                    button
                    className={selectedIndex === indx ? classes.selected : classes.listItemRoot}
                    key={indx}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedIndex();
                        setSelectedSubitemIndex(indx);
                        props.history.push(itm.route);
                    }}
                >
                    <ListItemText primary={itm.name} />
                </ListItem>
            ))}
        </List>
    );
}
export default withRouter(NestedMenuItem);
