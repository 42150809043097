import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openDrawer } from '../../actions/actions';
import { designColors } from '../../data/constant';
import NestedMenu from './NestedMenu';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
        // '& .MuiListItemText-root': {
        //     color: `${designColors.blue} !important`
        // }
    },
    listItemRoot: {
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        fontWeight: 'bold',
        minWidth: 32,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    selected: {
        marginTop:'-1px',
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        fontWeight: 'bold',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        },
    },
    menuBox: {
        display: 'flex',
        borderRadius: '10px',
        width: '45px',
        border: '2px solid white',
        padding: '8px',
        alignContent: 'center !important'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        // marginRight: 36,
        // border: '1px solid white',
        borderRadius: 0,
        // padding: 0,
        justifyContent: 'end'
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
        // color: '#fff !important',
        // backgroundColor: `${designColors.blue} !important`
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        top: '104px',
        color: '#fff',
        backgroundColor: `${designColors.blue}`
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        overflowX: 'hidden',
        width: theme.spacing(9) + 2,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(9) + 1
        // },
        top: '104px',
        color: '#fff',
        backgroundColor: `${designColors.blue}`
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    paperAnchorDockedLeft: {
        border: 'none'
    }
}));

function MiniDrawer(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const history = useHistory();
    useEffect(() => {
        if (history.location.pathname === '/dashboard/' && selectedIndex !== 0) {
            setSelectedIndex(0);
        } else if ((history.location.pathname === '/' || history.location.pathname === '/home/') && selectedIndex !== 1) {
            setSelectedIndex(1);
        } else if (history.location.pathname === '/MasterDataManagement/' && selectedIndex !== 2) {
            setSelectedIndex(2);
        } else if(history.location.pathname === '/export/' && selectedIndex !== 3){
            setSelectedIndex(3);
        } else if (history.location.pathname === '/Releases/' && selectedIndex !== 4) {
            setSelectedIndex(4);
        } 
    }, [history.location.pathname, selectedIndex]);
    const handleDrawerOpen = () => {
        props.openDrawer(true);
    };

    const handleDrawerClose = () => {
        props.openDrawer(false);
    };

    const handleListItemClick = (itm, index) => {
        setSelectedIndex(index);
        props.handleListItemClick(itm, index);
    };

    if (props.allLinks.length <= 0) return null;
    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: props.drawerOpen,
                [classes.drawerClose]: !props.drawerOpen
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: props.drawerOpen,
                    [classes.drawerClose]: !props.drawerOpen,
                    [classes.paperAnchorDockedLeft]: true
                })
            }}
        >
            {/* <div className={classes.toolbar}> */}
            {/* <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton> */}
            <IconButton
                color="inherit"
                aria-label="close drawer"
                onClick={handleDrawerClose}
                edge="start"
                disableRipple
                className={clsx(classes.menuButton, {
                    [classes.hide]: !props.drawerOpen
                    // [classes.root]: true
                })}
            >
                <Box component="span" sx={{ p: 1, border: '1px solid white', borderRadius: 8 }}>
                    <ChevronLeftIcon />
                    <MenuIcon />
                </Box>
            </IconButton>
            {/* </div> */}
            {/* <Divider /> */}

            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                disableRipple
                className={clsx(classes.menuButton, {
                    [classes.hide]: props.drawerOpen
                    // [classes.root]: true
                })}
            >
                <Box component="span" sx={{ p: 1, border: '1px solid white', borderRadius: 8 }}>
                    <MenuIcon />
                    <ChevronRightIcon />
                </Box>
            </IconButton>
            <List>
                {!!props.drawerOpen && <Divider variant="middle" style={{ backgroundColor: 'white' }} />}
                {props.allLinks.map((menu, index) =>
                    menu.items ? (
                        <>
                            <NestedMenu
                                menu={menu}
                                index={index}
                                onClick={(event) => handleListItemClick(menu, index)}
                                setSelectedIndex={() => setSelectedIndex(index)}
                                handleDrawerOpen={handleDrawerOpen}
                                selected={selectedIndex === index}
                                classes={classes}
                            />
                        </>
                    ) : (
                        <>
                            <ListItem
                                button
                                key={index}
                                onClick={(event) => handleListItemClick(menu, index)}
                                selected={selectedIndex === index}
                                className={selectedIndex === index ? classes.selected : classes.listItemRoot}
                            >
                                {props.drawerOpen ? (
                                    <ListItemText primary={menu.name} /> //classes={{ root: classes.listItemRoot }}
                                ) : (
                                    <div className={classes.menuBox}>
                                        <ListItemIcon className={selectedIndex === index ? classes.selected : classes.listItemRoot}>
                                            {menu.key}
                                        </ListItemIcon>
                                    </div>
                                )}
                            </ListItem>
                            {!!props.drawerOpen && selectedIndex !== index && <Divider variant="middle" style={{ backgroundColor: 'white' }} />}
                        </>
                    )
                )}
            </List>
        </Drawer>
    );
}
const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});

export default connect(mapStateToProps, { openDrawer })(MiniDrawer);
