import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import logo from '../../Images/logo.png';
import { withOktaAuth } from '@okta/okta-react';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import MiniDrawer from './MiniDrawer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { designColors } from '../../data/constant';
import { Link } from 'react-router-dom';
import { setUserLang } from '../../actions/MasterActions';
import UpArrow from '../../Images/Up-Arrow.svg';
import SignOutIcon from '../../Images/log-out.svg';
import DrcLanguageHeaderButton from '../DrcLanguageHeaderButton';
import { DrcTranslate, DrcEnvironmentMarker } from '@driscollsinc/driscolls-react-components';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    listItemRoot: {
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        fontWeight: 'bold'
    },
    selected: {
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        fontWeight: 'bold'
    },
    menuBox: {
        display: 'flex',
        borderRadius: '10px',
        width: '45px',
        border: '2px solid white',
        padding: '8px',
        alignContent: 'center !important'
    },
    logo: {
        display: 'flex',
        width: '177px',
        paddingRight: '30px'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        paddingRight: '25px'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        // marginRight: 36,
        // border: '1px solid white',
        // borderRadius: '8px',
        // padding: 0,
        justifyContent: 'end'
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
        // color: '#fff !important',
        // backgroundColor: `${designColors.blue} !important`
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        top: '64px',
        color: '#fff',
        backgroundColor: `${designColors.blue}`
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(9) + 1,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(9) + 1
        // },
        top: '64px',
        color: '#fff',
        backgroundColor: `${designColors.blue}`
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    titleText: {
        height: '24px',
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1.11px',
        textAlign: 'left',
        color: '#fff',
        marginTop: '10px'
    },
    headerButton: {
        color: '#ffffff',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: `${designColors.hover}`
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: `${designColors.hover}`,
                backgroundColor: '#0000'
            }
        }
    },
    menuIndicator: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
        marginTop: '5px',
        '& img': {
            '-webkit-transition-duration': '0.2s',
            '-moz-transition-duration': '0.2s',
            '-o-transition-duration': '0.2s',
            'transition-duration': '0.2s',
            '-webkit-transition-property': '-webkit-transform',
            '-moz-transition-property': '-moz-transform',
            '-o-transition-property': '-o-transform',
            'transition-property': 'transform'
        }
    },
    userMenuItemText: {
        marginLeft: '50px',
        marginTop: '-24px',
        fontSize: '14px'
    },
    userMenuItem: {
        cursor: 'pointer',
        position: 'relative',
        borderRadius: '0 0 5px 5px',
        borderTop: `1px solid ${theme.palette.common.white}`,
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        padding: '10px 10px',
        '&:hover': {
            backgroundColor: `${designColors.hover} !important`,
            '& div:nth-child(2)': {
                display: 'block'
            }
        }
    },
    menuIndicatorDown: {
        transform: 'rotate(0deg)'
    },
    menuIndicatorUp: {
        transform: 'rotate(-180deg)'
    },
    userMenuDropDown: {
        transition: 'visibility 0.3s, opacity 0.3s',
        right: '12px',
        top: '90px',
        display: 'block',
        borderRadius: '5px',
        position: 'fixed',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        width: 'auto',
        minWidth: '200px'
    },
    userMenuTarget: {
        justifyContent: 'right',
        display: 'flex',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    userMenuItemIcon: {
        width: '20px',
        marginLeft: '10px'
    },
    userMenuItemLast: {},
    userMenuInfo: {
        cursor: 'default',
        justifyContent: 'left',
        paddingRight: '20px',
        marginLeft: 'auto'
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        verticalAlign: 'middle',
        padding: '25px 25px 13px 40px'
    }
}));

function Header(props) {
    const classes = useStyles();
    const [allowedLinks, setAllowedLinks] = React.useState(props.allLinks);
    const [user, setUser] = React.useState('');
    const [dropdown, setDropdown] = React.useState(false);

    useEffect(() => {
        checkAuthentication();
        (async () => {
            await getUser();
        })();
    }, [props.authState]);

    const checkAuthentication = () => {
        if (!props.authState) {
            //|| !props.authState?.isAuthenticated
            if (allowedLinks.length > 0) {
                setAllowedLinks([]);
            }

            return;
        }

        let token = props.authState?.accessToken;

        if (!token || token.length <= 0) {
            if (allowedLinks.length > 0) {
                setAllowedLinks([]);
            }
        } else {
            var allAllowedLinks = [];

            var matchedLinks = props.allLinks.filter((l) => {
                if ((l.requiresGroups || []).length > 0) {
                    return l.requiresGroups.some((rg) => DuAuthenticationUtilities.IsInGroup(token, rg));
                }

                return true;
            });

            if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_ADMIN_GROUPS || [])) {
                allAllowedLinks = matchedLinks;
            } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_REGULAR_GROUPS || [])) {
                allAllowedLinks = matchedLinks.filter((link) => !link.requiresAdmin);
                allAllowedLinks = allAllowedLinks.map((link) => {
                    link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin);
                    return link;
                });
            } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_READ_ONLY_GROUPS || [])) {
                allAllowedLinks = matchedLinks.filter((link) => !link.requiresAdmin && !link.requiresRegular);
                allAllowedLinks = allAllowedLinks.map((link) => {
                    link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin && !subLink.requiresRegular);
                    return link;
                });
            }

            if (allowedLinks.length !== allAllowedLinks.length) {
                setAllowedLinks(allAllowedLinks);
            }
        }
    };

    const handleListItemClick = (itm, index) => {
        props.history.push(itm.route);
    };
    const getUser = async () => {
        let token = await props.oktaAuth.getAccessToken();
        let userId = DuAuthenticationUtilities.GetUserId(token) || '';
        setUser(userId.split('.').join(' '));
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.drawerOpen
                })}
            >
                <Toolbar
                    position="static"
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: 'unset',
                        padding: '0px'
                    }}
                >
                    <DrcEnvironmentMarker />
                    <div className={classes.headerContainer}>
                        <Link to="/dashboard">
                            <img id="logo" className={classes.logo} src={logo} alt="logo" />
                        </Link>
                        <Typography variant="h6" noWrap className={classes.titleText} style={{ paddingRight: '2rem' }}>
                            RESEARCH DATA REPOSITORY
                        </Typography>

                        <div style={{ justifyContent: 'right', paddingRight: '20px', marginLeft: 'auto' }}>
                            <DrcLanguageHeaderButton
                                className={classes.headerButton}
                                isSecondary
                                noStyle
                                handleLangChange={(code, lang) => {
                                    props.setUserLang(code);
                                    sessionStorage.setItem('RDR_UserLang', code);
                                }}
                            />
                        </div>
                        <div
                            tabIndex="1"
                            id="userMenu"
                            className={classes.userMenuTarget}
                            onBlur={(e) => {
                                if (!e.currentTarget.contains(e.relatedTarget)) {
                                    setDropdown(!dropdown);
                                }
                            }}
                            onClick={() => {
                                setDropdown(!dropdown);
                            }}
                        >
                            <div className={classes.userMenuInfo}>
                                {props.authState?.isAuthenticated && (
                                    <Typography noWrap style={{ fontSize: '14px' }}>
                                        {`${user}`}
                                        <br /> RDR Accountant
                                    </Typography>
                                )}
                            </div>
                            {props.authState?.isAuthenticated && (
                                <>
                                    <div
                                        style={{
                                            borderLeft: '3px solid white',
                                            height: '45px',
                                            paddingRight: '10px'
                                        }}
                                    />
                                    <AccountCircleIcon style={{ height: '47px', width: '47px' }} fontSize="large" />
                                    <div
                                        tabIndex={0}
                                        className={clsx(classes.menuIndicator, {
                                            [classes.menuIndicatorDown]: dropdown,
                                            [classes.menuIndicatorUp]: !dropdown
                                        })}
                                    >
                                        <img src={UpArrow} alt="" />
                                    </div>
                                </>
                            )}

                            {dropdown && (
                                <div className={classes.userMenuDropDown}>
                                    <div
                                        className={classes.userMenuItem + ' ' + classes.userMenuItemLast}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.history.push('/logout/');
                                        }}
                                    >
                                        <img src={SignOutIcon} className={classes.userMenuItemIcon} alt="" />
                                        <div className={classes.userMenuItemText}>
                                            <DrcTranslate>{'Sign Out'}</DrcTranslate>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <MiniDrawer allLinks={allowedLinks || []} handleListItemClick={handleListItemClick} />
        </div>
    );
}
const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});
export default connect(mapStateToProps, { setUserLang })(withOktaAuth(withRouter(Header)));
