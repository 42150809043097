import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
// this might not be required just keeping it here for now
export const USER_ROLES_TO_GROUP_MAP = {
    Admin: 'SF_Admin',
    'Master Planner': 'SF_MasterPlanner',
    'Regional Forecaster': 'SF_Regional',
    'National Forecaster': 'SF_National'
};

const roleUtilities = (token) => {
    var allGroups = DuAuthenticationUtilities.GetGroups(token) || [];

    var allAppGroups = window.config.OKTA_ALL || [];

    var matchedGroups =
        allGroups.filter((group) => {
            return allAppGroups.includes(group);
        }) || [];

    var hasAdminGroup = matchedGroups.find((group) => {
        return group.toLowerCase().includes('admin');
    });

    var roleName = '';

    if (hasAdminGroup) {
        roleName = 'Admin';
    } else if (matchedGroups.length > 0) {
        roleName = matchedGroups[0];
    }

    if (roleName.includes('-')) {
        roleName = roleName.substring(roleName.indexOf('-') + 1);

        while (roleName.includes('-')) {
            roleName = roleName.replace('-', ' ');
        }
    }

    return roleName;
};

export default roleUtilities;
