import { DrcMain } from '@driscollsinc/driscolls-react-components';
import { makeStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { hideLoadingScreenAction, setPageTitleAction, showLoadingScreenAction } from '../actions/actions';

const allGroups = window.config.OKTA_ALL || [];
const pageTitle = 'Home';
const useStyles = (props) =>
    makeStyles((theme) => ({
        mainContainer: {
            margin: `104px auto 0  ${props.drawerOpen ? '240px' : '74px'}`,
            minHeight: 'calc(100vh - 105px)',
            maxWidth: 'none !important',
            borderRadius: '40px 0 0 40px'
        },
        labelStyle: {
            display: 'inline-block',
            margin: '20px 20px 0 20px',
            position: 'relative',
            top: '-15px'
        },
        flexContainer: {
            height: '100%',
            padding: '0',
            margin: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        flexRow: {
            width: 'auto',
        },
        flexItem: {
            padding: '5px',
            margin: '10px',
            lineHeight: '20px',
            fontWeight: 'bold',
            fontSize: '2em',
            textAlign: 'center'
        }
    }));

function DrcPageNotFound(props) {
    const classes = useStyles(props)();

    useEffect(() => {
        if (props.pageTitle !== pageTitle) {
            props.setPageTitle(pageTitle);
        }
    }, []);

    return (
        <DrcMain className={classes.mainContainer}>
            <div className={classes.flexContainer}>
                <div className={classes.flexRow}>
                    <div className={classes.flexItem}>Page Not Found!</div>
                </div>
            </div>
        </DrcMain>
    );
}

function mapStateToProps(state) {
    return {
        drawerOpen: state.rootReducer.drawerOpen,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(DrcPageNotFound));
