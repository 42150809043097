export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const SET_FILTER_STATUS = 'SET_FILTER_STATUS';
export const setFilterStatus = (filter) => ({
    type: SET_FILTER_STATUS,
    payload: filter
});

export const setFilterUploadedBy = (filter) => ({
    type: 'SET_FILTER_UPLOADED_BY',
    payload: filter
});

export const SET_USER_LANG = 'SET_USER_LANG';
export const setUserLang = (lang = 'en-US') => ({
    type: SET_USER_LANG,
    payload: lang
});

