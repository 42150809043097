// [English, Spanish, Chinese, French]

const APP_TRANSLATIONS = {
    berries: {
        black: ['Blackberry', 'Mora', '黑莓', 'la mûre'],
        blacks: ['Blackberries', 'Moras', '黑莓', 'Mûres'],
        blue: ['Blueberry', 'Arándano', '蓝莓', 'Myrtille'],
        blues: ['Blueberries', 'Arándanos', '蓝莓', 'Myrtilles'],
        rasp: ['Raspberry', 'Framboise', '覆盆子', 'Framboise'],
        rasps: ['Raspberries', 'Frambuesas', '山莓', 'Framboises'],
        straw: ['Strawberry', 'Fresa', '草莓', 'Fraise'],
        straws: ['Strawberries', 'Fresas', '草莓', 'Des fraises']
    },
    ImportData: ['Import Data', 'Importar Datos', '导入数据', 'Importer des données'],
    buttonTempSubmit: ['Submit', 'Enviar', '提交', 'Soumettre'],
    STATUS: ['STATUS', 'ESTADO', '状态', 'STATUT'],
    Select: ['Select', 'Seleccione', '选择', 'Sélectionner'],
    Active: ['Active', 'Activo', '积极的', 'actif'],
    Processing: ['Processing', 'Procesando', '加工', 'Traitement'],
    'Action Required': ['Action Required', 'Acción requerida', '需要采取的行动', 'Action requise'],
    'UPLOADED BY': ['UPLOADED BY', 'SUBIDO POR', '上传者', 'TELECHARGÉ PAR'],
    'Download Template': ['Download Template', 'Descargar plantilla', '下载模板', 'Télécharger le modèle'],
    'Export Data To Excel': ['Export Data To Excel', 'Exportar datos a Excel', '将数据导出到 Excel', 'Exporter des données vers Excel'],
    Import: ['Import', 'Importar', '进口', 'Importer'],
    Welcome: ['Welcome', 'Bienvenidos', '欢迎', 'Bienvenu'],
    Breadcrumbs: ['Breadcrumbs', 'Migas de pan', '面包屑', 'Chapelure'],
    Accept: ['Accept', 'Aceptar', '接受', "J'accepte"],
    Cancel: ['Cancel', 'Cancelar', '取消', 'Annuler'],
    Edit: ['Edit', 'Editar', '编辑', 'Éditer'],
    View: ['View', 'Vista', '看法', 'Voir'],
    'View History': ['View History', 'Ver historial', '查看历史', "Voir l'historique"],
    'Apply Filter': ['Apply Filter', 'Aplicar Filtro'],
    FILTER: ['FILTER', 'FILTRAR', '筛选', 'FILTRE'],
    'CLEAR ALL': ['CLEAR ALL', 'LIMPIAR TODO', '清除所有', 'TOUT EFFACER'],
    'Reset Filter': ['Reset Filter', 'Reiniciar Filtro'],
    logo: ['logo', 'logo', '商标', 'Peuples'],
    'RDR Accountant': ['RDR Accountant', 'Contador RDR', 'RDR 会计师', 'Comptable RDR'],
    Summary: ['Summary', 'Resumen', '概括', 'Sommaire'],
    Log: ['Log', 'Tronco', '日志', 'Gens'],
    'This feature will add missing data automatically into systems. No further actions required. (recommended)': [
        'This feature will add missing data automatically into systems. No further actions required. (recommended)',
        'Esta característica agregará automáticamente los datos faltantes a los sistemas. No se requieren más acciones. (recomendado)',
        '此功能将自动将丢失的数据添加到系统中。无需进一步操作。 （推荐的）',
        'Cette fonctionnalité ajoutera automatiquement les données manquantes dans les systèmes. Aucune autre action requise. (conseillé)'
    ],
    "This feature will delete the uploaded file then the missing data should be added manually under 'Data setup'. After this process, fresh upload of the same file should be initiated. Download error log before Abort.":
        [
            "This feature will delete the uploaded file then the missing data should be added manually under 'Data setup'. After this process, fresh upload of the same file should be initiated. Download error log before Abort.",
            "esta función eliminará el archivo cargado y luego los datos faltantes se deben agregar manualmente en 'Configuración de datos'. Después de este proceso, se debe iniciar una nueva carga del mismo archivo. Descargue el registro de errores antes de cancelar.",
            '此功能将删除上传的文件，然后应在“数据设置”下手动添加丢失的数据。在此过程之后，应启动相同文件的新上传。在中止之前下载错误日志。',
            'Cette fonctionnalité supprimera le fichier téléchargé, puis les données manquantes devront être ajoutées manuellement sous "Configuration des données". Après ce processus, un nouveau téléchargement du même fichier doit être lancé. Téléchargez le journal des erreurs avant d\'abandonner.'
        ],
    'Do you want to abort this upload or create the missing values?': [
        'Do you want to abort this upload or create the missing values?',
        '¿Desea cancelar esta carga o crear los valores que faltan?',
        '是否要中止此上传或创建缺失值？',
        'Voulez-vous annuler ce téléchargement ou créer les valeurs manquantes ?'
    ],
    'Missing values': ['Missing values', 'Valores faltantes', '缺失值', 'Valeurs manquantes'],
    'Upload failed with errors, Please download the log file to check more details.': [
        'Upload failed with errors, Please download the log file to check more details.',
        'La carga falló con errores. Descargue el archivo de registro para verificar más detalles.',
        '上传失败并出现错误，请下载日志文件以查看更多详细信息。',
        'Le téléchargement a échoué avec des erreurs, veuillez télécharger le fichier journal pour vérifier plus de détails.'
    ],
    'Error Found': ['Error Found', 'Error encontrado', '发现错误', 'Erreur trouvée'],
    'Download the log report': ['Download the log report', 'Descargar el informe de registro', '下载日志报告', 'Télécharger le rapport de journal'],
    'Auto Fix': ['Auto Fix', 'Corrección automática', '自动修复', 'Correction automatique'],
    Abort: ['Abort', 'Abortar', '中止', 'Avorter'],
    Add: ['Add', 'Agregar', '添加', 'Ajouter'],
    Close: ['Close', 'Cerrar', '关闭', 'Fermer'],
    Save: ['Save', 'Salvar', '保存', 'sauvegarder'],
    'Cannot add new record': [
        'Cannot add new record',
        'No se puede agregar un nuevo registro',
        '无法添加新记录',
        "Impossible d'ajouter un nouvel enregistrement"
    ],
    'View Details': ['View Details', 'Ver detalles', '查看详情', 'Voir les détails'],
    Entity: ['Entity', 'Entidad', '实体', 'Entité'],
    Samples: ['Samples', 'Muestras', '样品', 'Échantillons'],
    Selections: ['Selections', 'Trozos escogidos', '选择', 'Sélections'],
    'VarMarket Set': ['VarMarket Set', 'Conjunto VarMarket', 'VarMarket 集', 'Ensemble VarMarket'],
    'VarMarket Species': ['VarMarket Species', 'Especies VarMarket', 'VarMarket 品种', 'Espèces VarMarket'],
    'Genetic Map': ['Genetic Map', 'Mapa Genético', '遗传图谱', 'Carte génétique'],
    'Physical Map': ['Physical Map', 'Mapa físico', '实物图', 'Carte physique'],
    'Market Trait Association': [
        'Market Trait Association',
        'Asociación de rasgos de mercado',
        '市场特征协会',
        'Association des caractéristiques du marché'
    ],
    'Named variants': ['Named variants', 'Variantes con nombre', '命名变体', 'Variantes nommées'],
    Vendors: ['Vendors', 'Vendedores', '供应商', 'Vendeurs'],
    Others: ['Others', 'Otros', '其他', 'Les autres'],
    Copy: ['Copy', 'Dupdo', '复制', 'Copie'],
    Delete: ['Delete', 'Borrar', '删除', 'Supprimer'],
    'Upload data': ['Upload data', 'Subir datos', '上传数据', 'Télécharger des données'],
    'Select vendor': ['Select vendor', 'Seleccionar proveedor', '选择供应商', 'Sélectionnez le fournisseur'],
    Vendor: ['Vendor', 'Vendedor', '小贩', 'Vendeur'],
    'Choose a file': ['Choose a file', 'Escoge un archivo', '选择一个文件', 'Choisissez un fichier'],
    'or drag it here': ['or drag it here', 'o arrástralo aquí', '或者拖到这里', 'ou faites-le glisser ici'],
    'Only *.vcf, csv file should be uploaded for acceptance.': [
        'Only *.vcf, csv file should be uploaded for acceptance.',
        'Solo se debe cargar el archivo *.vcf, csv para su aceptación.',
        '仅应上传 *.vcf、csv 文件以供接受。',
        'Seul le fichier *.vcf, csv doit être téléchargé pour acceptation.'
    ],
    'one_file_upload_instruction':[
        'Only one file can be uploaded at a time.',
        'Solo se puede cargar un archivo a la vez.',
        '一次只能上传一个文件。',
        'Un seul fichier peut être téléchargé à la fois.'

    ],
    'Uploading part': ['Uploading part', 'Cargando parte', '上传部分', 'Partie de téléchargement'],
    of: ['of', 'de', '的', 'désactivé'],
    Submit: ['Submit', 'Enviar', '提交', 'Nous faire parvenir'],
    'Contact & Support': ['Contact & Support', 'Soporte de contacto', '联系与支持', 'Contactez le support'],
    Training: ['Training', 'Capacitación', '训练', 'Formation'],
    'All rights reserved.': ['All rights reserved.', 'Todos los derechos reservados.', '版权所有。', 'Tous les droits sont réservés.'],
    'Terms and Condition': ['Terms and Condition', 'Términos y Condiciones', '附带条约', 'Termes et conditions'],
    'Privacy Statement': ['Privacy Statement', 'Declaracion de privacidad', '隐私声明', 'Déclaration de confidentialité'],
    'UPLOAD RDR FILE': ['UPLOAD RDR FILE', 'CARGAR ARCHIVO RDR', '上传 RDR 文件', 'TÉLÉCHARGER LE FICHIER RDR'],
    'VIEW RDR DATA': ['VIEW RDR DATA', 'VER DATOS RDR', '查看 RDR 数据', 'VOIR LES DONNÉES RDR'],
    'MASTER DATA': ['MASTER DATA', 'DATOS MAESTROS', '主要的数据', 'DONNÉES DE BASE'],
    'All attributes & entities related data': [
        'All attributes & entities related data',
        'Todos los atributos y datos relacionados con las entidades',
        '所有属性和实体相关数据',
        'Toutes les données liées aux attributs et aux entités'
    ],
    BerryType: ['Berry Type', 'Tipo de baya', '浆果类型', 'Type de baie'],

    //masterdata columns - starts
    Actions: ['Actions', 'Comportamiento', '行动', 'Actions'],
    BerrySpecies: ['Berry Species', 'Especies de bayas', '浆果种类', 'Espèces de baies'],
    VarietyName: ['Variety Name', 'Nombre de la variedad', '品种名称', 'Nom de la variété'],
    PriorityLevel: ['Priority Level', 'Nivel de prioridad', '优先级', 'Niveau de priorité'],
    OverallStatus: ['Overall Status', 'Estado general', '整体状况', 'Situation globale'],
    Pedigree: ['Pedigree', 'Árbol genealógico', '谱系', 'pedigree'],
    BreedingProgram: ['Breeding Program', 'Programa de cría', '育种计划', "Programme d'élevage"],
    MarketingEntityCommissionRate: [
        'Marketing Entity Commission Rate',
        'Tasa de comisión de la entidad de comercialización',
        '营销实体佣金率',
        "Taux de commission de l'entité marketing"
    ],
    ShareDriscollsFarming: [
        'Share Driscolls Farming',
        'Compartir Driscolls Farming',
        '分享 Driscolls Farming',
        "Partagez l'établissement Driscolls Farming"
    ],
    ShareGrower: ['Share Grower', 'Compartir cultivador', '分享种植者', 'Partager le producteur'],
    MidsizeDriscollsFarming: [
        'Midsize Driscolls Farming',
        'Agricultura mediana de Driscolls',
        '中型 Driscolls 农业',
        'Agriculture Driscolls de taille moyenne'
    ],
    MidsizeGrower: ['Midsize Grower', 'Productor mediano', '中型种植者', 'Producteur de taille moyenne'],
    //masterdata columns - ends

    //Research data columns - starts
    FileName: ['File Name', 'Nombre del archivo', '文件名', 'Nom de fichier'],
    UploadStatus: ['Upload Status', 'Cargar estado', '上传状态', 'Statut de téléchargement'],
    UploadedDateTime: ['Uploaded Time', 'Tiempo subido', '上传时间', 'Heure de téléchargement'],
    UploadedBy: ['Uploaded By', 'Subido por', '上传者', 'Telechargé par'],
    DatabricksStatus:['DataBricks Status','Estado de los bloques de datos','数据块状态','Statut des DataBricks'],
    //Research data columns - ends

    //Dashboard
    upload_subheading: [
        'Upload csv file into system',
        'Subir archivo csv al sistema',
        '将csv文件上传到系统',
        'Télécharger le fichier csv dans le système'
    ],
    viewdata_subheading: [
        'View the list of uploaded files',
        'Ver la lista de archivos subidos',
        '查看上传文件列表',
        'Afficher la liste des fichiers téléchargés'
    ],
    masterdata_subheading: ['Manage Master data', 'Administrar datos maestros', '管理主数据', 'Gérer les données de base'],

    //filter
    Filter: ['Filter', 'Filtrar', '筛选', 'Filtre'],
    Status: ['Status', 'Estado', '状态', 'Statut'],
    'Uploaded By': ['Uploaded By', 'Subido por', '上传者', 'Telechargé par'],
    'Geno Filename': ['Geno Filename', 'Geno Nombre de archivo', '基因文件名', 'Nom du fichier Géno'],
    'Berry Type': ['Berry Type', 'Tipo de baya', '浆果类型', 'Type de baie'],
    Apply: ['Apply', 'Aplicar', '申请', 'Appliquer'],
    Reset: ['Reset', 'Reiniciar', '重置', 'Réinitialiser'],

    //filter-master data
    'Sample Id': ['Sample Id', 'Ejemplo de identificacion', '样品编号', "Identifiant de l'échantillon"],
    'Created By': ['Created By', 'Creado por', '由...制作', 'Créé par'],
    'Alternate Allele': ['Alternate Allele', 'Alelo alternativo', '替代等位基因', 'Allèle alternatif'],
    'Reference Allele': ['Reference Allele', 'Alelo de referencia', '参考等位基因', 'Allèle de référence'],
    'Varmarkers PK Id': ['Varmarkers PK Id', 'ID de PK de marcadores Var', 'Varmarkers PK ID', 'Identifiant PK des marqueurs de variables'],
    'VarmarkerSet Id': ['VarmarkerSet Id', 'Id. de conjunto Varmarker', 'VarmarkerSet ID', "ID de l'ensemble de marqueurs de variables"],
    'Vendor Name': ['Vendor Name', 'Nombre del vendedor', '供应商名称', 'Nom du fournisseur'],

    //master data management
    FileHeaderId: ['File Header Id', 'ID de encabezado de archivo', '文件头 ID', "ID d'en-tête de fichier"],
    UpdatedBy: ['Updated By', 'Actualizado por', '更新者', 'Mis à jour par'],
    SampleId: ['Sample Id', 'Ejemplo de identificacion', '样品编号', "Identifiant de l'échantillon"],
    UpdatedDateTime: ['Updated Date Time', 'Fecha y hora actualizada', '更新日期时间', 'DateHeure mise à jour'],
    AlternateAllele: ['Alternate Allele', 'Alelo alternativo', '替代等位基因', 'Allèle alternatif'],
    ReferenceAllele: ['Reference Allele', 'Alelo de referencia', '参考等位基因', 'Allèle de référence'],
    VarmarkersPKId: ['Varmarkers PK Id', 'ID de PK de marcadores Var', 'Varmarkers PK ID', 'Identifiant PK des marqueurs de variables'],
    VarmarkerId: ['Varmarker Id', 'ID de marcador de Var', '可变标记 ID', 'ID de marqueur de variable'],
    VarmarkerSetId: ['Varmarker Set Id', 'Id. de conjunto Varmarker', 'VarmarkerSet ID', "ID de l'ensemble de marqueurs de variables"],
    VendorName: ['Vendor Name', 'Nombre del vendedor', '供应商名称', 'Nom du fournisseur'],
    CreatedBy: ['Created By', 'Creado por', '由...制作', 'Créé par'],
    CreatedDateTime: ['Created Date Time', 'Fecha de creación Hora', '创建日期时间', 'Date de création Heure'],

    BerrytypeMandatory:['Berry Type is mandatory','El tipo de baya es obligatorio','浆果类型是强制性的','Le type de baie est obligatoire'],
    MultiFilterRequired:['At least one filter is mandatory along with Berry Type','Al menos un filtro es obligatorio junto con Berry Type','至少一个过滤器是强制性的以及浆果类型','Au moins un filtre est obligatoire avec Berry Type'],
    'Request processed successfully':['Request processed successfully','Solicitud procesada con éxito','请求处理成功','Demande traitée avec succès']
};

export default APP_TRANSLATIONS;
