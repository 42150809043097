import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import masterReducer from './MasterReducer';

import {
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_COMMON_DIALOG,
    HIDE_ERROR_DIALOG,
    SET_PAGE_TITLE,
    SHOW_TOAST,
    OPEN_DRAWER,
    SERVICE_WORKER_UPDATED,
    SERVICE_WORKER_UPDATE,
    SET_USER_IS_ADMIN
} from '../actions/actions';

const initialState = {
    showLoadingScreen: false,
    pageTitle: '',
    commonDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Error Occurred'
    },
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: (action.payload || '').length > 0 ? 'RDR: ' + action.payload : 'Research Data Repository'
            });

        case SET_USER_IS_ADMIN:
            console.log('mmmmm in reducers');
            return {
                ...state,
                userIsAdmin: action.payload
            };
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_COMMON_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors,
                    isMessage: !!action.payload.isMessage
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                isSuccess: action.payload.isSuccess
            };
        case OPEN_DRAWER:
            return {
                ...state,
                drawerOpen: action.payload
            };
        case SERVICE_WORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            };
        case SERVICE_WORKER_UPDATED:
            return {
                ...state,
                serviceWorkerUpdated: false
            };

        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    localize: localizeReducer,
    masterReducer
});
