/* 
This is a style-guide component. Once all the necessary changes are done in this related to RDR, this will be added to Style-guide and then we will import it from there
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from 'react-localize-redux';
import Popover from '@material-ui/core/Popover';
import { DuCommonTranslations } from '@driscollsinc/driscolls-react-utilities';
import { DrcButton } from '@driscollsinc/driscolls-react-components';
import Language from '@material-ui/icons/Language';

/**
 *@ignore
 */

const styles = (theme) => ({
    headerButtons: {
        float: 'right',
        minWidth: 0,
        border: 'none !important',
        margin: 4,
        '& svg': {
            fontSize: 30
        }
    },
    langPopover: {
        '& .MuiPaper-elevation8': {
            padding: '4px 12px',
            maxWidth: 400,
            width: '100%'
        }
    },
    language: {
        width: 120,
        float: 'right',
        height: 22
    },
    languageBtn: {
        width: '100%',
        margin: '8px 0'
    }
});

/**
 * @description DrcLanguageHeaderButton is a styled, controlled button to select languages for common translation
 * @class DrcLanguageHeaderButton
 * @property {json} activeLanguage Sets the active language
 * @example
 * <DrcLanguageHeaderButton />
 * @category Input
 * @tags input button language important header top internationalization
 * @extends {React.Component}
 */

class DrcLanguageHeaderButton extends Component {
    /**
     * Creates an instance of DrcLanguageHeaderButton.
     * @param {*} props
     * @memberof DrcLanguageHeaderButton
     */
    constructor(props) {
        super(props);

        this.state = {
            anchorElLanguage: null
        };
    }

    /**
     * @description Function to handle the event of language change
     * @param {*} lang
     * @memberof DrcLanguageHeaderButton
     */
    changeLanguage = (lang) => {
        this.props.setActiveLanguage(lang.code);
        this.props.handleLangChange && this.props.handleLangChange(lang.code, lang);
        this.handleLanguageClose();
    };

    /**
     * @description Function to handle the event of clicking on a language button
     * @param {*} event
     * @memberof DrcLanguageHeaderButton
     */
    handleLanguageClick = (event) => {
        this.setState({ anchorElLanguage: event.currentTarget });
    };

    /**
     * @description Function to handle close event for a language
     * @memberof DrcLanguageHeaderButton
     */
    handleLanguageClose = () => {
        this.setState({ anchorElLanguage: null });
    };

    /**
     * @return {*}
     * @memberof DrcLanguageHeaderButton
     * @constant LANGUAGES list of languages supported  [{ name: 'English', code: 'en' }]
     */
    render() {
        var { classes, className, LANGUAGES, ...others } = this.props;

        const languageOpen = Boolean(this.state.anchorElLanguage);
        const languageId = languageOpen ? 'lang-popover' : undefined;
        return (
            <React.Fragment>
                <DrcButton
                    aria-describedby={languageId}
                    className={`${classes.headerButtons} ${className}`}
                    ariaLabel={'language'}
                    noStyle
                    onClick={this.handleLanguageClick}
                    {...others}
                >
                    <Language />
                </DrcButton>
                <Popover
                    id={languageId}
                    open={languageOpen}
                    anchorEl={this.state.anchorElLanguage}
                    onClose={this.handleLanguageClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    className={classes.langPopover}
                >
                    <div className="row">
                        {(LANGUAGES || DuCommonTranslations.LANGUAGES).map((lang) => {
                            return (
                                <div className="col-xs-12 col-sm-6" key={lang.code}>
                                    <DrcButton
                                        noStyle
                                        isPrimary={this.props.activeLanguage && this.props.activeLanguage.code === lang.code}
                                        isSecondary={!this.props.activeLanguage || this.props.activeLanguage.code !== lang.code}
                                        className={this.props.classes.languageBtn}
                                        onClick={() => this.changeLanguage(lang)}
                                    >{`${lang.name} - ${lang.code}`}</DrcButton>
                                </div>
                            );
                        })}
                    </div>
                </Popover>
            </React.Fragment>
        );
    }
}

export default withLocalize(withStyles(styles)(DrcLanguageHeaderButton));
