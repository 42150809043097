import {
    DrcBackdrop,
    DrcButton,
    DrcDialog,
    DrcEnvironmentMarker,
    DrcImplicitCallback,
    DrcLoading,
    DrcMain,
    DrcMediaQueries,
    DrcPageLogin,
    DrcPanel,
    DrcSecureGroupRoute,
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcTranslate,
    Helmet
} from '@driscollsinc/driscolls-react-components';
import { DuCommonTranslations, DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { DirectionsRun, History } from '@material-ui/icons';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { createHashHistory } from 'history';
import React, { lazy, Suspense } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
    addInfo,
    hideCommonDialogAction,
    hideLoadingScreenAction,
    serviceWorkerUpdate,
    setCommonDialogAction,
    setPageTitleAction,
    showLoadingScreenAction,
    showToast
} from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/MasterActions';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import APP_TRANSLATIONS from './data/appTranslations';
import { designColors } from './data/constant';
import LoggingUtilities from './data/LoggingUtilities';
import MasterDataUtilities from './data/MasterDataUtilities';
import BackgroundPng from './Images/Bowl_Mixed_3.png';
import BackgroundWebP from './Images/Bowl_Mixed_3.webp';
import DrcPageNotFound from './pages/DrcPageNotFound';
import ErrorBoundary from './pages/ErrorBoundary';
import InitializeApplication from './pages/InitializeApplication';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const LogOut = lazy(() => import('./pages/LogOut'));
const Home = lazy(() => import('./pages/Home'));
const MasterDataManagement = lazy(() => import('./pages/MasterDataManagement'));
const Releases = lazy(() => import('./pages/Releases'));
const ExportData = lazy(()=> import('./pages/ExportData'));

const allGroups = window.config.OKTA_ALL || [];
const SITE_NAME = 'Research Data Repository';

const styles = (theme) => ({
    '@global': {
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },
        '::-webkit-scrollbar-track': {
            /* Track */ backgroundColor: '#d8d8d8',
            borderRadius: '5px'
        },
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        '::-webkit-scrollbar-thumb': {
            /* Handle */ backgroundColor: '#9b9b9b',
            borderRadius: '5px'
        },
        '.MuiButton-root': {
            fontSize: '1rem',
            boxShadow: 'none'
        },
        body: {
            backgroundColor: '#fff'
        }
    },
    logoLink: {
        left: 'calc(50vw - 70px)',
        position: 'absolute'
    },
    logo: {
        height: 40,
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    logoShowAlways: {
        height: 40
    },
    header: {
        background: 'transparent',
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 97),
        '& .MuiToolbar-root': {
            padding: '0px 12px'
        },
        '& .titleContainer h1': {
            display: 'contents'
        },
        '& .title': {
            textShadow: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsla(341, 57%, 15%, 1)',
            '& .title': {
                color: 'hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        },
        '@media print': {
            display: 'none'
        }
    },
    loaderWithMessage: {
        width: '644px',
        left: 'calc(50vw - 322px)',
        top: 'calc(50vh - 60px)',
        position: 'fixed',
        padding: '20px',
        backgroundColor: '#000',
        borderRadius: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#ddd'
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.blue,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },

    mainContainer: {
        margin: '104px auto 0 74px',
        borderRadius: '40px 0 0 40px'
    },
    baseContainer: {
        backgroundColor: `${designColors.blue}`,
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: `${designColors.black} !important`
        }
    },
    loadingSection: {
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '100vh',
        paddingTop: '150px'
    },
    loginContainer: {
        height: '100vh',
        width: '100%',
        display: 'flex'
    },
    loginBackground: {
        position: 'absolute',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100vh'
    },
    loadingcontainer:{
        paddingTop:'60px'
    }
});

const allLinks = [
    {
        title: <DrcTranslate>{'Log'}</DrcTranslate>,
        icon: <History style={{ width: '18px', height: '18px' }} />,
        url: '/home/Log/',
        requiresGroups: allGroups
    },
    {
        title: <DrcTranslate>{'Sign Out'}</DrcTranslate>,
        url: '/LogOut/',
        icon: <DirectionsRun />
    }
];

class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createHashHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.blue)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: {
                ...DuCommonTranslations.COMMON_TRANSLATIONS,
                ...APP_TRANSLATIONS
            },
            options: { renderToStaticMarkup }
        });

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/home/');

        this.closeCommonDialog = this.closeCommonDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setCommonDialogAction, false);
        DuThemeUtilities.RegisterTheme(DuThemeUtilities.ButtonThemes.Hard);
    }

    closeCommonDialog() {
        this.props.hideCommonDialog();
    }

    handleCloseNotification = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        const { classes } = this.props;
        const menuItems = [
            {
                key: 'DB',
                name: <DrcTranslate>Dashboard</DrcTranslate>,
                route: '/dashboard/'
            },
            {
                key: 'VRD',
                name: <DrcTranslate>View RDR Data</DrcTranslate>,
                route: '/home/'
            },
            {
                key: 'DR',
                name: <DrcTranslate>Data Setup</DrcTranslate>,
                route: '/MasterDataManagement/'
            },
            {
                key:'EXP',
                name: <DrcTranslate>Export Data</DrcTranslate>,
                route: '/export/'
            },
            {
                key: 'RE',
                name: <DrcTranslate>Releases</DrcTranslate>,
                route: '/Releases/'
            }
        ];

        return (
            <div className={classes.baseContainer}>
                <DrcThemeProvider fontFamily="Rubik" theme={this.state.theme}>
                    <DrcEnvironmentMarker />
                    <Helmet>
                        <meta
                            name="description"
                            content="The new Research Data Repository system will enable RnD s ability to perform modern data and analytics requires centralized, secure and accessible data storage that can meet unique requirements of scientific experimentation. There is a critical business need to ingest and store Genotypic and Phenotypic data enabling researchers to conduct genomic  prediction analysis"
                        />
                        <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                    </Helmet>
                    <Router history={Router.browserHistory}>
                        <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                            <ErrorBoundary path={window.location.pathname}>
                                <Header allLinks={menuItems} />
                                <Suspense
                                    fallback={
                                        <DrcMain className={classes.mainContainer} transparent>
                                            <DrcPanel>
                                                <DrcLoading />
                                            </DrcPanel>
                                        </DrcMain>
                                    }
                                >
                                    <Switch>
                                        <Route
                                            path="/"
                                            exact
                                            render={(props) => (
                                                <div className={classes.loginContainer}>
                                                    <img src={BackgroundPng} className={classes.loginBackground}></img>

                                                    <DrcPageLogin
                                                        {...props}
                                                        poly
                                                        setPageTitle={this.props.setPageTitleAction}
                                                        backgroundPng={BackgroundPng}
                                                        backgroundWebP={BackgroundWebP}
                                                    />
                                                </div>
                                            )}
                                        />

                                        <DrcSecureGroupRoute
                                            path="/InitializeApplication/"
                                            exact
                                            component={InitializeApplication}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute path="/home/" exact component={Home} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute
                                            path="/MasterDataManagement"
                                            exact
                                            component={MasterDataManagement}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute
                                            path="/MasterDataManagement/"
                                            component={MasterDataManagement}
                                            groupsAllowed={allGroups}
                                        />
                                        <DrcSecureGroupRoute path="/dashboard" exact component={Dashboard} groupsAllowed={allGroups} />
                                        <DrcSecureGroupRoute path="/export" exact component={ExportData} groupsAllowed={allGroups} />

                                        <Route
                                            path="/implicit/callback"
                                            render={(props) => (
                                                <div className={classes.loadingcontainer}><DrcImplicitCallback {...props} backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} /></div>
                                            )}
                                        />
                                        <Route path="/LogOut/" exact component={LogOut} />
                                        <Route component={DrcPageNotFound} />
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </Security>
                        {this.props.showLoadingScreen && (
                            <div className={classes.loadingSection}>
                                <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingMessage} />
                            </div>
                        )}
                        <DrcDialog
                            isError={this.props.commonDialog.isMessage}
                            title={this.props.commonDialog.title}
                            open={this.props.commonDialog.show}
                            buttons={
                                <DrcButton poly line isError onClick={this.closeCommonDialog}>
                                    OK
                                </DrcButton>
                            }
                        >
                            {this.props.commonDialog.content}
                        </DrcDialog>
                        <DrcDialog
                            title={'New version available'}
                            open={this.props.serviceWorkerUpdated}
                            buttons={
                                <>
                                    <DrcButton onClick={this.closeNewVersionDialog}>Close</DrcButton>
                                    <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                        Ok
                                    </DrcButton>
                                </>
                            }
                        >
                            <div>Refresh to update your app?</div>
                        </DrcDialog>
                        {window.location.pathname !== '/' ?  <Footer allowClick={this.props.isInitialized} /> : ''}
                    </Router>

                    {this.props.toastMessage && (
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            className={this.props.isSuccess ? classes.successToast : classes.errorToast}
                            open={this.props.toastMessage !== ''}
                            autoHideDuration={3000}
                            onClose={this.handleCloseNotification}
                            message={<span id="message-id">{this.props.toastMessage}</span>}
                        />
                    )}
                </DrcThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        loadingMessage: state.rootReducer.loadingScreenMessage,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
