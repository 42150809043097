import React, { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import NestedMenuItem from './NestedMenuItems';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { designColors } from '../../data/constant';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4)
    },
    menuBox: {
        display: 'flex',
        borderRadius: '10px',
        width: '45px',
        border: '2px solid white',
        padding: '8px',
        alignContent: 'center !important'
    },
    listItemRoot: {
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        fontWeight: 'bold',
        minWidth: 32
    },
    selected: {
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        fontWeight: 'bold'
    }
}));

function NestedMenu(props) {
    const [isExpanded, setExpanded] = React.useState(false);
    const classes = useStyles();
    const { menu, drawerOpen, selected, setSelectedIndex, handleDrawerOpen } = props;
    useEffect(() => {
        if (isExpanded && (!drawerOpen || !selected)) {
            setExpanded(false);
        }
    }, [drawerOpen, selected]);
    return (
        <>
            <ListItem
                className={selected ? classes.selected : classes.listItemRoot}
                button
                onClick={(e) => {
                    e.stopPropagation();
                    handleDrawerOpen();
                    setExpanded(!isExpanded);
                }}
            >
                {drawerOpen && (
                    <>
                        <ListItemText primary={menu.name} />
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </>
                )}
                {!drawerOpen && (
                    <div className={classes.menuBox}>
                        <ListItemIcon className={selected ? classes.selected : classes.listItemRoot}>{menu.key}</ListItemIcon>
                    </div>
                )}
            </ListItem>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <NestedMenuItem menu={menu} setSelectedIndex={setSelectedIndex} selected={selected} />
            </Collapse>
        </>
    );
}

const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});

export default connect(mapStateToProps, null)(NestedMenu);
