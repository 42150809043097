import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DrcVersionInfo } from '@driscollsinc/driscolls-react-components';
import { designColors } from '../../data/constant';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '11px 0',
        display: 'flex',
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
    },
    content: {
        padding: '0 1rem',
        zIndex: '2000'
    },
    rightContent: {
        flex: 1,
        height: '30px',
        backgroundColor: '#0076a500',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
    }
}));

function Footer(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.rightContent}>
                <DrcVersionInfo allowClick={props.allowClick} />
            </div>
        </div>
    );
}

export default Footer;
